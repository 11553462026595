@import "../variables";

a {
    color: $link-color;
    text-decoration: none;
}

a.button {
    display: block;
    padding: 1em;
    margin: 1em;
    background-color: $secondary-background-color;
    border-radius: 1em;
    color: $font-color;
    border: 1px solid $link-color;

    &:hover, &:focus {
        background-color: darken($color: $secondary-background-color, $amount: 5%);
        box-shadow: 0px 0px 6px 2px $link-color;
        outline: none;
    }
}

a:not(.button) {
    &:hover, &:focus {
        color: lighten($color: $font-color, $amount: 5%);
    }
}

a.button, a.icon {
    user-select: none;
}

a > i.fa {
    padding-right: .25em;
}