@import "variables";

.App {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    background-color: $primary-background-color;
    color: $font-color;
    text-align: center;
}

body {
    background-color: $secondary-background-color;
}