@import "../../variables";

.labeled-input {
    label {
        font-weight: 500;
    }

    input {
        display: block;
        background-color: $primary-background-color;
        color: white;
        width: 4em;
        margin: 0 1em;
        text-align: center;
    }
}