@import "../variables";

.simulator-card {
    padding: 1em;
    margin: 1em 0;
    max-width: 95vw;

    box-shadow: 4px 4px 10px 0px $drop-shadow-fade;

    background-color: $secondary-background-color;
    border-radius: 2em;

    scroll-margin-top: 2em;

    > h1 {
        max-width: 80vw;
        margin: 0 auto;
    }

    &:nth-child(even) .simulator-card-content {
        flex-direction: row-reverse;
    }

    .expand-button {
        position: relative;
        float: right;
        padding-right: .25em;
        padding-top: .25em;
        font-size: 1.5em;
    }
}

.simulator-card-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    justify-content: center;
    align-items: center;

    overflow-wrap: break-word;

    > * {
        flex: 1 1 0px;
        max-width: 22em;
    }

    .simulator-card-description {
        padding: 0 0.5em;
        ul {
            margin-top: 0;
        }
    }
}

@media only screen and (max-width: 999px) {
    .simulator-card {
        padding: .25em;
    }
}