@import "../variables";

header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .75em;

    background-color: $secondary-background-color;
    user-select: none;

    .page-header {
        margin: 0 0.5em;
        font-size: 2em;
    }

    img {
        width: 3em;
        border-radius: 1.5em;
    }
}
