$primary-background-color: #22212f;
$font-color: #F2F2FF;
$link-color:  darken($font-color, 8%);

$secondary-background-color: lighten($primary-background-color, 10%);
$secondary-border-color: lighten($secondary-background-color, 15%);

$drop-shadow-fade: #0000008C;

$transition-length: 350ms;

@keyframes fade-in {
    from { opacity: 0; }
    to { opacity: 1; }
}
