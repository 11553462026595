.arcade-sim-frame {
    height: 350px;

    margin: 1em;

    iframe {
        height: 100%;
    }

    &.large-view {
        iframe {
            width: 100%;
        }

        width: 100vw;
        height: 100vh;
        max-height: 70vh;
        max-width: 97vw;
        margin: 0;
    }
}