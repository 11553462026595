@import "../../variables";

$full-alpha-border: 2px solid #000000AA;
$light-alpha-border: 2px solid #00000077;

.color-picker {
    border: $full-alpha-border;
    padding: 2em;
    border-radius: 1em;
    background-color: $secondary-background-color;
    display: flex;
    flex-direction: column;
    align-items: center;

    .primary {
        display: flex;
        flex-direction: column;
        align-items: center;

        .color-picker-primary-surface {
            user-select: none;
            border: $light-alpha-border;
            width: 300px;
            height: 200px;
        }

        .color-picker-hue-bar {
            border: $light-alpha-border;
            margin-top: 1em;
            width: 300px;
            height: 20px;
        }

        .color-picker-canvas {
            touch-action: none;
            width: 100%;
            height: 100%;
        }

        .color-picker-output {
            display: flex;
            cursor: pointer;
            flex-direction: row;
            align-items: center;
            margin-top: 2em;
            font-family: monospace;

            background-color: $primary-background-color;
            border: $light-alpha-border;
            border-radius: 0.5em;
            padding: 0.5em;
            user-select: none;

            .color-picker-current {
                // color the components of the color preview
                :nth-child(1) {
                    color: #FFFFFFAA;
                }
                :nth-child(2) {
                    color: rgb(231, 156, 156);
                }
                :nth-child(3) {
                    color: rgb(136, 245, 136);
                }
                :nth-child(4) {
                    color: rgb(138, 138, 219);
                }
                :nth-child(5) {
                    color: #FFFFFFBB;
                }
            }

            .color-picker-preview {
                width: 2em;
                height: 2em;
                border: $full-alpha-border;
                margin-left: 1em;
                border-radius: 2em;
            }
        }
    }

    .inputs {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-top: 1em;
    }
}

