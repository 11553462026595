@import "../variables";

footer {
    background-color: $secondary-background-color;
    padding: .75em;

    > *:not(:first-child) {
        border-left: 1px solid $font-color;
        margin-left: 1em;
        padding-left: 1em;
    }
}