@import "../variables";

main.content {
    display: flex;

    flex: 1;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-between;

    > *:not(#go-home-link) {
        display: flex;
        flex: 1;
        justify-content: center;
        flex-direction: column;
    }

    ul {
        display: inline-block;
        list-style: inside;
        padding: 0;
    }
}

.fade-enter.fade-enter-active {
    animation: fade-in ease-in $transition-length;
}

.fade-exit {
    opacity: 0;
}
